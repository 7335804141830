import { HOC_MDXWrapper } from 'nextra/setup-page'
import { pageMap } from '/ui/docs-site/.next/static/chunks/nextra-page-map-.mjs'
/*@jsxRuntime automatic*/
/*@jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
const title = "404";
const frontMatter = {};
import {Icon, NotFoundPage} from '@/components';
import {mdiHelpCircle} from '@mdi/js';
export function useTOC(props) {
  return [];
}
function MDXLayout(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return <NotFoundPage><_components.p>{"Sorry, we can’t find the page you’re looking for. You can either return to\nthe home page or use the search bar to find a different page."}</_components.p><_components.p>{"If you’re looking for product release notes, sign in to your Chronosphere\napplication, and in the navigation menu select\n"}<_components.strong><Icon path={mdiHelpCircle} />{" Help "}<span aria-label="and then">{">"}</span>{" Release Notes"}</_components.strong>{"."}</_components.p></NotFoundPage>;
}


export default HOC_MDXWrapper(
  MDXLayout,
  '/404',
  {"filePath":"pages/404.mdx",pageMap,frontMatter,title},
  typeof RemoteContent === 'undefined' ? useTOC : RemoteContent.useTOC
)